  .ChartContainer1 {
    position: relative;
    height: 220px;
  }
  
  .ChartContainer {
    display: flex;
    justify-content: space-between;
    /* border: 1px dashed lightgray; */
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    left: 40px;
  }

  .OverLay {
    position: absolute;
    display: flex;
    background-color: #eceaea;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
    top: 0;
    left: 0;
  }
  
  .StyledBox>div {
    width: 20px;
    height: 20px;
    margin: 0 4px;
    border-radius: 4px;
  }
  
  .StyledBox2 div {
    width: 16px;
    height: 16px;
    margin: 0 4px;
    border-radius: 4px;
  }
  
  .BarContainer {
    display: flex;
    height: 220px;
    width: 30px;
    flex-direction: column-reverse;
  }

  .XAxis {
    display: flex;
    width: 30px;
    flex-direction: column;
    align-items: center;
  }
  
  .BarSubContainer {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }
  
  .HorizontalGrid {
    width: 100%;
    height: 1px;
    border-top: 1px dashed lightgrey;
    position: absolute;
  }

  .Baseline {
    height: 1px;
    flex-direction: column-reverse;
    border-top: 1px solid black;
    position: absolute;
  }
  
  .VerticalGrid {
    width: 1px;
    height: 100%;
    border-left: 1px dashed lightgrey;
    position: absolute;
  }
  
  .VerticalLabels {
    position: absolute;
    left: -35px;
  }
  
  .tooltipHolder {
    pointer-events: auto !important;
  }
  .tooltipHolder:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  
  *, *:after, *:before {
    box-sizing: border-box;
  }

  .tooltipCustom {
    height: 100%;
    width: 100%;
  }