text.recharts-text {
  fill: #000000;
  font-size: 10px;
}

.recharts-text-strong {
  font-weight: 600;
}

line.recharts-cartesian-axis-line {
  stroke: none;
}

line.recharts-cartesian-axis-tick-line {
  stroke: none;
}

.custom-tooltip {
  background: rgba(255, 255, 255, 0.8);
  border: 0.5px solid rgba(0, 0, 0, 0.33);
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
}

.tooltipLabelTitle {
  font-weight: 600;
  font-size: 11px;
  color: #000000;
  text-transform:capitalize;
  margin: 3px 0;
}

.tooltipLabel {
  font-size: 9px;
  color: #000000;
  text-transform:capitalize;
  margin: 3px 0;
}

.tooltipLabelStrong {
  font-weight: 600;
}