.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul, .rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}

.rc-pagination-total-text {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  vertical-align: middle;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 29px;
  height: 29px;
  margin-right: 8px;
  font-family:  Arial, Helvetica, Verdana, Tahoma;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}

.rc-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #363BC4;
  transition: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

.rc-pagination-item a:hover {
  text-decoration: none;
}

.rc-pagination-item:focus, .rc-pagination-item:hover {
  border-color: #1890ff;
  transition: all 0.3s;
}

.rc-pagination-item:focus a, .rc-pagination-item:hover a {
  color: #1890ff;
}

.rc-pagination-item-active {
  background: #363BC4;
}

.rc-pagination-item-active a {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}

.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a {
  color: #ffffff;
}

.rc-pagination-jump-prev, .rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button, .rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #363BC4;
}

.rc-pagination-jump-prev button:after, .rc-pagination-jump-next button:after {
  display: block;
  content: '•••';
}

.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial, Helvetica, Verdana, Tahoma;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-pagination-prev, .rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button, .rc-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}

.rc-pagination-prev:hover button, .rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #202370;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.rc-pagination-prev:focus .rc-pagination-item-link, .rc-pagination-prev:hover .rc-pagination-item-link, .rc-pagination-next:focus .rc-pagination-item-link, .rc-pagination-next:hover .rc-pagination-item-link {
  color: #202370;
  border-color: #202370;
}

.rc-pagination-prev button:after {
  content: '‹';
  display: block;
}

.rc-pagination-next button:after {
  content: '›';
  display: block;
}

.rc-pagination-disabled, .rc-pagination-disabled:hover, .rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {
  .rc-pagination-options *::-ms-backdrop, .rc-pagination-options {
    vertical-align: top;
  }
}

.rc-pagination-options-size-changer.rc-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}

.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev, .rc-pagination-simple .rc-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  background: hsv(0, 0, 96%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #ccc;
  border-color: transparent;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: hsv(0, 0, 96%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .rc-pagination-item-after-jump-prev, .rc-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .rc-pagination-options {
    display: none;
  }
}